/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "../styles/base.scss"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import CookieConsent from "./CookieConsent"
import Cookies from "universal-cookie"

const Layout = ({ children, contentType, headerMod, btnMod }) => {

  const isCookieConsent = () => {
    const cookies = new Cookies();
    const acceptCookies = cookies.get('acceptCookies');
    if ( !acceptCookies ) {
      return (<CookieConsent/>)
    }
  }

  return (
    <>
      <div className={`page-container ${contentType}`}>
        <Header headerMod={headerMod} btnMod={btnMod} />
        <main>{children}</main>
        <Footer/>
      </div>
      {isCookieConsent()}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  contentType: PropTypes.string,
  headerMod: PropTypes.string,
  btnMod: PropTypes.string,
}

Layout.defaultProps = {
  contentType: '',
  headerMod: 'header-normal',
  btnMod: '--gradient'
}

export default Layout
