import React from "react"
import Cookies from 'universal-cookie';
import './CookieConsent.scss'
import Cookie from '../images/cookie.svg'
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"

const CookieConsent = () => {
  const acceptCookie = (e) => {
    const cookies = new Cookies();
    cookies.set('acceptCookies', true, { path: '/' });
    const acceptCookies = new Event('acceptCookies');
    window.dispatchEvent(acceptCookies)
  }
  return (
    <div className="cookie-consent cookie-mode-normal">
      <Cookie />
      <div className="cookie-consent__content">
        <h5><FormattedMessage id="cookies.title" /></h5>
        <p><FormattedMessage id="cookies.we_use_cookies" /></p>
        <p><FormattedHTMLMessage id="cookies.know_more" /></p>
        <div className="cookie-consent__buttons">
          <button className="vbtn vbtn--gradient" onClick={e => acceptCookie(e)}><FormattedMessage id="buttons.accept" /></button>
          <button className="cookie-consent__more-info"><FormattedMessage id="cookies.more_info" /></button>
        </div>
      </div>
    </div>
  )
};

export default CookieConsent