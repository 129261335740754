import React from "react"
import { useIntl, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import './language.scss'

const languageName = {
  pt: "PT",
  en: "EN",
}

const Language = () => {
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const changeLang = (e, language) => {
    e.preventDefault();
    changeLocale(language);
    return false;
  }

  return (
    <div className="language-switcher">
      <label htmlFor="switcher" className="language-switcher__current">{languageName[intl.locale]}</label>
      <input type="checkbox" id="switcher" />
      <ul>
        <IntlContextConsumer>
          {({ languages, language: currentLocale }) =>
            languages.map(language => (
              <li className={currentLocale === language ? `lang-active` : ``} key={language}>
                <a
                  key={language}
                  onClick={ (e) => { currentLocale !== language ? changeLang(e, language) : e.preventDefault()} }
                  lang={language}
                  href={`${data.site.siteMetadata.siteUrl}/${language}`}
                >
                  {languageName[language]}
                </a>
              </li>
            ))
          }
        </IntlContextConsumer>
      </ul>
    </div>
  )
}

export default Language