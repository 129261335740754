import React from "react"
import LogoN from '../images/logo.svg'
import './footer.scss'
import { FormattedMessage, FormattedHTMLMessage, Link } from "gatsby-plugin-intl"

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-2 offset-md-1 offset-lg-2 mb-3">
            <LogoN/>
          </div>
          <div className="col-12 col-md-9 col-lg-4 offset-lg-1">
            © <a href="https://www.sitana.pt">SITANA</a> 2019<br/>
            <FormattedHTMLMessage id="footer.sitana" />
            < br/>
            <Link to="/terms-and-conditions/"><FormattedMessage id="footer.terms_conditions" /></Link> -&nbsp;
            <Link to="/terms-and-conditions/"><FormattedMessage id="footer.privacy_policy" /></Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer