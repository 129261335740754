import React from "react"
import { FaShoppingCart } from 'react-icons/fa';
import { Link } from "gatsby-plugin-intl"
import './MiniCart.scss'

const MiniCart = () => {
  return (
    <div className="mini-cart" style={{ marginLeft: 30 }}>
      <Link to="/cart/">
        <FaShoppingCart/>
        <span className="mini-cart__number">0</span>
      </Link>
    </div>
  )
};

export default MiniCart