import PropTypes from "prop-types"
import React from "react"
import MiniCart from './MiniCart'
import Language from './language'
import LogoN from '../images/logo.svg'
import './header.scss'
import { FormattedMessage, Link } from "gatsby-plugin-intl"

const Header = ({ siteTitle, headerMod, btnMod }) => {
  return (
    <header className={headerMod}>
      <div className="container main-nav-container">
        <Link to="/">
          <LogoN />
        </Link>
        <nav className="main-nav">
          <ul>
            <li><Link to="/servers/"><FormattedMessage id="menu.servers" /></Link></li>
            <li><Link to="/virtual-machines/"><FormattedMessage id="menu.vms" /></Link></li>

            <li><Link to="/cloud/"><FormattedMessage id="menu.hosting" /></Link></li>
            <li><Link to="/contacts/"><FormattedMessage id="menu.contacts" /></Link></li>
            <li>
              <Link to="/login/" className={`vbtn vbtn${btnMod} align-self-right header-btn`}><FormattedMessage id="buttons.login" /></Link>
            </li>
          </ul>
          <div className="burger-menu">
            <div className="burger-menu__line"></div>
            <div className="burger-menu__line"></div>
            <div className="burger-menu__line"></div>
          </div>
        </nav>
        <Link to="/login/" className={`vbtn vbtn${btnMod} align-self-right header-btn`}><FormattedMessage id="buttons.login" /></Link>
        <Language />
        <MiniCart />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  headerMod: PropTypes.string,
  btnMod: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
